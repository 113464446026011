import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ApexChart from "../../../components/ApexChart/ApexChart.jsx";
import { CHARTS } from "../../../components/ApexChart/ApexChartConstant.js";
import PatternIframe from "../component/PatternIframe.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Data visualization is a complex task. Choosing a certain way to display multiple pieces of information is often confusing. It can be static or dynamic and can change over time. Selecting the proper data visualization is essential to avoid data misinterpretation. This pattern will recommend the data visualization based on the function that the user needs and some use cases to provide better decision-making when using the component.`}</p>
    <p>{`Data visualization is divided into 4 categories to help navigate the charts based on the relationship between data, throughout the time. 4 categories are Comparison, Composition, Relationship, and Distribution.`}</p>
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/data-visualization/comparison" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Guideline visualization`}</strong>{`: Various information about data visualization are presented based on the goal of the chart and will be updated periodically.`}</p>
    </InlineNotification>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Comparison</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Composition</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Relationship</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Distribution</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <h2>{`Comparison`}</h2>
    <p>{`Using visualization to compare one or many values sets is so much easier than looking at numbers in the grid. Column and line charts are probably the most used.`}</p>
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.bar.options} series={CHARTS.bar.options.series} width={CHARTS.bar.options.chart.width} height={CHARTS.bar.options.chart.height} type="bar" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Bar charts represent numerical values compared to each other. The length of the bar represents the value of each
      variable. One axis of the chart shows the specific categories being compared and the other axis represents a
      discrete value scale.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: The use of popular mobile device by quantity
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.column.options} series={CHARTS.column.options.series} width={CHARTS.column.options.chart.width} height={CHARTS.column.options.chart.height} type="bar" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Similar to bar chart, where the categories are placed on the x-axis. These are preferred over bar charts for short
      labels, date ranges, or negatives in values.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Package subscription analysis (over the year)
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.area.options} series={CHARTS.area.options.series} width={CHARTS.area.options.chart.width} height={CHARTS.area.options.chart.height} type="area" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Area chart connect data points with line segments, stacking variables on top of one another and using color to
      distinguish between variables. Area chart are used to display the development of quantitative values over an
      interval or time period.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Website usage statistic over time
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.spline.options} series={CHARTS.spline.options.series} width={CHARTS.spline.options.chart.width} height={CHARTS.spline.options.chart.height} type="area" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Spline chart that uses curves instead of straight lines. It is designed to emphasize trends in data over a time
      period but in a more smooth, gradual way than a line chart does.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Task status of employee over time
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.line.options} series={CHARTS.line.options.series} width={CHARTS.line.options.chart.width} height={CHARTS.line.options.chart.height} type="line" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      This chart is used to display quantitative values over a continuous interval or time period. Line chart is most
      frequently used to show trends and analyze how the data has changed over time.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Transaction of active customers over time
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.multiLine.options} series={CHARTS.multiLine.options.series} width={CHARTS.multiLine.options.chart.width} height={CHARTS.multiLine.options.chart.height} type="line" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Same as Line chart, Multiline captures numeric variables over time. Allowing comparison of different units and
      scale ranges.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Transaction by types of fossil fuel
    </p>
  </div>
    </div>
    <br />
    <div className="divi" />
    <h2>{`Composition`}</h2>
    <p>{`Show how a single entity can be broken down into its component elements. These charts are used to illustrate a part to a whole data, usually in percentages, with all the segments equaling to 100%.`}</p>
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.pie.options} series={CHARTS.pie.options.series} width={CHARTS.pie.options.chart.width} height={CHARTS.pie.options.chart.height} type="pie" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Pie Charts help show proportions and percentages between categories, by dividing a circle into proportional
      segments. Pie Charts are ideal for giving the reader a quick idea of the proportional distribution of the data.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Profiling percentage by age group in customer dashboard
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.donut.options} series={CHARTS.donut.options.series} width={CHARTS.donut.options.chart.width} height={CHARTS.donut.options.chart.height} type="donut" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Benefit of Donut Chart (compared to pie chart) is to make the viewer focus more on the changes in overall values.
      You are focused on reading the length of the arcs, rather than comparing the proportions between slices.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Statistic of customers who refuel their vehicles in a month
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.treemap.options} series={CHARTS.treemap.options.series} width={CHARTS.treemap.options.chart.width} height={CHARTS.treemap.options.chart.height} type="treemap" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Treemap chart is displaying the quantities for each category that is assigned a rectangle area with the
      subcategory rectangles nested inside. Treemaps are also great at comparing the proportions between categories via
      their area size.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Comparison of college majors in a university
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.heatmap.options} series={CHARTS.heatmap.options.series} width={CHARTS.heatmap.options.chart.width} height={CHARTS.heatmap.options.chart.height} type="heatmap" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Heatmaps are good for showing variance across multiple variables, revealing any patterns, displaying whether any
      variables are similar to each other, and for detecting if any correlations exist. Heatmaps visualise by variations
      in colouring.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Busy hour over the weekdays in customer service dashboard
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.stackedColumn.options} series={CHARTS.stackedColumn.options.series} width={CHARTS.stackedColumn.options.chart.width} height={CHARTS.stackedColumn.options.chart.height} type="bar" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Shows the data series that are stacked one on top of the other in vertical columns. Stacked column charts can show
      changes over time because it's easy to compare total column lengths.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Production details per commodity in Agriculture
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.stackedArea.options} series={CHARTS.stackedArea.options.series} width={CHARTS.stackedArea.options.chart.width} height={CHARTS.stackedArea.options.chart.height} type="area" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Plotted in the form of several area series stacked on top of one another. Stacked Area chart also use the areas to
      convey whole numbers, so they do not work for negative values. they are useful for comparing multiple variables
      changing over time.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Workload status overtime
    </p>
  </div>
    </div>
    <br />
    <div className="divi" />
    <h2>{`Relationship`}</h2>
    <p>{`Using visualization to show relationship between two or more variables. Many readers will assume the relationship in the chart to be causal, i.e. one causes the other (unless you tell them otherwise).`}</p>
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.scatter.options} series={CHARTS.scatter.options.series} width={CHARTS.scatter.options.chart.width} height={CHARTS.scatter.options.chart.height} type="scatter" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      A Scatter chart function is to display all the values between two variables. Primarily used for correlation and
      distribution analysis. By having an axis for each variable, you can detect if a relationship or correlation
      between the two exists.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Relation between temperature and dates in differenct cities
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.lineScatter.options} series={CHARTS.lineScatter.options.series} type="line" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      A hybrid between a scatter plot and a line plot, the scatter dots are connected with a line for quickly surface
      potential relation.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Relation between Click Through Rate (CTR) and Conversion Rate in
      commerce website
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.bubble.options} series={CHARTS.bubble.options.series} width={CHARTS.bubble.options.chart.width} height={CHARTS.bubble.options.chart.height} type="bubble" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Bubble Charts is used to plot points along a grid where the X and Y axis are separate variables. Each point is
      assigned a label or category. Each plotted point then represents a third variable by the area of its circle and
      also colors.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Statistics of tasks among number of digital workers and their roles
    </p>
  </div>
    </div>
    <br />
    <div className="divi" />
    <h2>{`Distribution`}</h2>
    <p>{`Show values in a dataset and how often they occur. The shape of a distribution can be a way of highlighting the uniformity or equality in the data.`}</p>
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.boxPlot.options} series={CHARTS.boxPlot.series} type="boxPlot" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      Box plot chart is a convenient way of visually displaying the data distribution through their quartiles. Box plot
      is useful when comparing distributions between many groups or datasets. Box Plots can be drawn either vertically
      or horizontally.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Tren of product purchase over time
    </p>
  </div>
    </div>
    <br />
    <div className="row-chart">
  <div className="column-chart">
    <ApexChart options={CHARTS.histogram.options} series={CHARTS.histogram.options.series} type="bar" mdxType="ApexChart" />
  </div>
  <div className="double-column-chart">
    <p>
      A Histogram visualises the distribution of data over a continuous interval. Each bar in a histogram represents the
      tabulated frequency at each interval/bin. Histograms help give an estimate as to where values are concentrated.
      <br />
      <br />
      <span style={{
            "fontWeight": "bold"
          }}>Use Case</span>: Customer waiting time on monthly basis
    </p>
  </div>
    </div>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      